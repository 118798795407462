<template>
  <div>
    <el-dialog :value="openTalkDetails" top="3vh" width="60%" title="对话详情" :show-close="false" :destroy-on-close="true">
      <!-- 1.主体 -->
      <div class="flex-nowrap-flex-start height-min">
        <!-- 1.1左边列表 -->
        <div class="mr-15">
          <div class="flex-nowrap-flex-start">
            <div class="left-title">主叫所属用户：</div>
            <div class="left-text">{{ dataJson.nickName }}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">主叫：</div>
            <div class="left-text flex-nowrap-space-between">
              <div>{{ dataJson.telA }}</div>
              <el-button class="mr-10" type="danger" size="mini" v-if="dataJson.telADisabled == 0"
                @click="bannedCalling(dataJson)">禁用</el-button>
              <el-tag class="mr-10" type="danger" v-else>已禁用</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">被叫：</div>
            <div class="left-text flex-nowrap-space-between">
              <div>{{ dataJson.telB }}</div>
              <el-button class="mr-10" type="danger" size="mini" v-if="dataJson.telBDisabled == 0"
                @click="banCalled(dataJson)">禁用</el-button>
              <el-tag class="mr-10" type="danger" v-else>已禁用</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">企业号码：</div>
            <div class="left-text">{{ dataJson.telX }}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">通话时长：</div>
            <div class="left-text">{{ dataJson.duration }}秒</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">命中类型：</div>
            <div class="left-text">{{ dataJson.hitType }}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title-other">命中关键字：</div>
            <div class="left-text-other overflow-y">
              <el-tag class="right-width" v-for="(item,i) in hitTypeDescList" :key="i">{{ item }}</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">报备行业：</div>
            <div class="left-text">{{ dataJson.industry}}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title-other">报备话术：</div>
            <div class="left-text-other overflow-y">
              {{ dataJson.textarea1641434070934 }}
            </div>
          </div>
          <div class="bottom-box flex-nowrap-center-center">
            <audio class="recordSon-audio" :src="dataJson.recordUrl" controls="controls"></audio>
          </div>
        </div>
        <!-- 1.2右边对话 -->
        <div class="talkBox">
          <div v-for="(oitem,o) in dataJson.translatedContent" :key="o">
            <div class="talkBox-item" v-show="oitem.channel_id == 0">
              <div class="text-align-left">主叫</div>
              <div class="flex-nowrap-flex-start relative-box">
                <div>
                  <el-avatar :size="40" :src="require(`@/assets/images/telA.png`)"></el-avatar>
                </div>
                <div class="triangle"></div>
                <div class="ml-15 telA-box" v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
              </div>
            </div>
            <div class="talkBox-item" v-show="oitem.channel_id == 1">
              <div class="text-align-right">被叫</div>
              <div class="flex-nowrap-flex-end relative-box">
                <div class="mr-15 telB-box" v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
                <div class="triangle-other"></div>
                <div>
                  <el-avatar :size="40" :src="require(`@/assets/images/telB.png`)"></el-avatar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2.底部操作 -->
      <div class="flex-nowrap-space-between mt-10">
        <el-button type="primary" plain @click="copyText">复制质检记录</el-button>
        <div>
          <el-button type="primary" plain @click="next">下一个</el-button>
          <el-button @click="closeTalkDetails">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    openTalkDetails: {
      type: Boolean,
      default: false,
    },
    dataJson: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      hitTypeDescList: [],
    }
  },
  watch: {
    openTalkDetails(newObj, oldObj) {
      if (newObj == true) {
        if (
          this.dataJson.hitTypeDesc != '' &&
          this.dataJson.hitTypeDesc != null
        ) {
          this.hitTypeDescList = this.dataJson.hitTypeDesc.split(',')
        }
      }
    },
    'dataJson.hitTypeDesc': {
      deep: true,
      handler() {
        if (
          this.dataJson.hitTypeDesc != '' &&
          this.dataJson.hitTypeDesc != null
        ) {
          this.hitTypeDescList = this.dataJson.hitTypeDesc.split(',')
        }
      },
    },
  },
  computed: {},
  methods: {
    closeTalkDetails() {
      this.$emit('close', false)
    },
    copyText() {
      this.$emit('copy', true)
    },
    // 封禁用户
    bannedCalling(row) {
      this.$emit('bannedCalling', row)
    },
    // 封禁被叫
    banCalled(row) {
      this.$emit('banCalled', row)
    },
    next(row) {
      this.$emit('next', row)
    },
  },
}
</script>

<style scoped>
.flex-nowrap-flex-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.flex-nowrap-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.left-title {
  width: 120px;
  height: 45px;
  padding: 10px 0 10px 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}
.left-text {
  width: 207px;
  height: 45px;
  color: #333333;
  padding: 10px 0 10px 15px;
  border: 1px solid #ebebeb;
  border-left: none;
}
.left-title-other {
  width: 120px;
  height: 185px;
  line-height: 185px;
  padding-left: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}
.left-text-other {
  width: 207px;
  height: 185px;
  padding: 15px;

  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}
.bottom-box {
  height: 45px;
  border: 1px solid #ebebeb;
}
.recordSon-audio {
  width: 95%;
  height: 30px;
}
.talkBox {
  padding: 10px;
  width: 100%;
  overflow-y: overlay;
  height: 720px;
  background: #f3f3f3;
}
.float-right {
  float: right;
}
.text-align-left {
  padding-left: 65px;
  text-align: left;
}
.text-align-right {
  padding-right: 65px;
  text-align: right;
}
.telA-box {
  padding: 8px;
  background: #fff;
  border-radius: 6px;
}
.telB-box {
  padding: 8px;
  background: #9eea6a;
  border-radius: 6px;
}
.relative-box {
  position: relative;
}
.triangle {
  position: absolute;
  transform: rotate(-90deg);
  top: 39%;
  left: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffff transparent;
  width: 0px;
  height: 0px;
}
.triangle-other {
  position: absolute;
  transform: rotate(90deg);
  top: 39%;
  right: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}
.talkBox-item {
  padding: 2px 0;
}
.height-min {
  /* height: 500px; */
  overflow-y: overlay;
}
.right-width {
  margin-right: 10px;
  margin-bottom: 8px;
}
.overflow-y {
  overflow-y: overlay;
}
</style>
